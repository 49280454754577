import React, {useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

function OperatorTaxResidence(props) {
    const {values, handleChange} = useFormikContext();

    const [nexus] = useState([
        'RPONEX1',
        'RPONEX2',
        'RPONEX3',
        'RPONEX4',
        'RPONEX5',
    ]);

    return (
        <>
            <SelectTitle required={true} titleTranslationKey='compliance.dac7.form.field.operatorTaxResidence' />
            <RadioGroup
                placeholder={<FormattedMessage id={'compliance.dac7.form.field.operatorTaxResidence.placeholder'} />}
                alignListWithButton
                selectedValueLabel={values.operatorTaxResidence ? props.intl.formatMessage({id :`compliance.dac7.form.field.operatorTaxResidence.${values.operatorTaxResidence}`}): ''}
                name={'operatorTaxResidence'}
                value={values.operatorTaxResidence}
                onChange={(event) => handleChange(event)}
            >
                {nexus.map((code, index) => (
                    <FormControlLabel
                        key={index}
                        value={code}
                        label={<FormattedMessage id={`compliance.dac7.form.field.operatorTaxResidence.${code}`} />}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default injectIntl(OperatorTaxResidence);
