import React from 'react';

import {styled} from "@mui/styles";
import {DateTimePicker as MuiDateTimePicker} from "@mui/x-date-pickers/DateTimePicker";

import {
    blue50,
    blueColor,
    greyishBlue200,
    greyishBlue500
} from "assets/jss/main";

const StyledDateTimePicker = styled(MuiDateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: '8px',
        '& fieldset' : {
            border: `1px solid ${greyishBlue200}`,
        },
        '&:hover fieldset' : {
            border: `1px solid ${greyishBlue500}`,
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${blueColor}`,
            boxShadow: `0px 0px 0px 2px ${blue50}`,
        },
        '& .MuiInputBase-input': {
            fontSize: '14px',
            padding: '16px',
        }
    },
}));

function DateTimePicker(props) {
    return (
        <div>
            <StyledDateTimePicker
                {...props}
            />
        </div>
    );
}

export default DateTimePicker;
